<template>
  <div class='icon-danger'>
    <svg xmlns="http://www.w3.org/2000/svg" width="151.846" height="143.234" viewBox="0 0 151.846 143.234">
      <path id="Path_38106" data-name="Path 38106" d="M148.765,124.276l-53.7-98.859a22.27,22.27,0,0,0-38.295,0c-.031.052-.056.1-.087.157L3.162,124.12a22.26,22.26,0,0,0,19.143,33.633H129.233A22.5,22.5,0,0,0,148.886,146.5,21.946,21.946,0,0,0,148.765,124.276ZM67.012,59.785a8.906,8.906,0,0,1,17.812,0V95.41a8.906,8.906,0,1,1-17.812,0V59.785Zm8.906,80.156a13.359,13.359,0,1,1,13.359-13.359A13.374,13.374,0,0,1,75.918,139.941Z"
        transform="translate(-0.001 -14.519)" :fill="color"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconDanger',
  props: {
    color: {
      type: String,
      required: false,
      default: '#DC3435',
    },
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang='scss' scoped>
</style>
